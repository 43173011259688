<template>
  <div id="app">
    <lemon-imui
        :width="lemonWidth"
        :height="lemonHeight"
        simple
        :user="user"
        ref="IMUI"
        @pull-messages="handlePullMessages"
        @message-click="handleMessageClick"
        @send="handleSend"
    >
      <template #cover>
        <div class="cover">
          <span><b>李棋</b>同学</span>
        </div>
      </template>
    </lemon-imui>
  </div>
</template>

<script>
const getTime = () => new Date().getTime();
const generateRandId = () => Math.random().toString(36).substr(-8);

export default {
  name: "test_Index8",
  components: {},
  props: {
    lemonWidth: {
      type: String,
      default: '100%'
    },
    lemonHeight: {
      type: String,
      default: '100%'
    },
    user: {
      id: "1",
      displayName: "June",
      avatar: "",
    },
    roomName: {
      type: String,
      default: '一起看'
    }
  },
  data() {
    return {
      oldMessageSize: 0
    };
  },
  mounted() {
    const {IMUI} = this.$refs;
    IMUI.initContacts([
      {
        id: "2",
        displayName: this.roomName + "_房间",
        avatar: "",
        type: "single",
        index: "T",
        unread: 0,
      },
    ]);
    IMUI.initEditorTools([])
    IMUI.clearMessages(2)
    setTimeout(() => {
      IMUI.changeContact("2");
      // const messages = this.$emit('sentMessage', this.oldMessageSize);
      // messages.forEach(item => {
      //   IMUI.appendMessage(item);
      // })
      IMUI.messageViewToBottom();
    }, 500);
  },
  methods: {
    renderMessage(data) {
      const {IMUI} = this.$refs;
      IMUI.appendMessage(data);
      IMUI.messageViewToBottom();
    },
    /**
     * 消息重发
     * @param e
     * @param key
     * @param message
     */
    handleMessageClick(e, key, message) {
      const {IMUI} = this.$refs;
      if (key == "status" && message.status === "failed") {
        IMUI.updateMessage({
          id: message.id,
          toContactId: message.toContactId,
          status: "going",
          content: "重新发送消息...",
        });
        setTimeout(() => {
          IMUI.updateMessage({
            id: message.id,
            toContactId: message.toContactId,
            status: "failed",
            content: "还是发送失败",
          });
        }, 2000);
      }
    },
    /**
     * 消息发送
     * @param message
     * @param next
     * @param file
     */
    handleSend(message, next, file) {
      this.$emit('sentMessage', message)
      // next({
      //   status: "succeed",
      // });
    },
    /**
     * 拉取最新消息
     * @param contact
     * @param next
     */
    handlePullMessages(contact, next) {
      this.$emit("pullMessage", this.oldMessageSize, val => {
        this.oldMessageSize = +val.length
        // true 表示到末尾
        next(val, val.length < 50 ? true : false);
      })
    },
    messageViewToBottom() {
      const {IMUI} = this.$refs;
      IMUI.messageViewToBottom();
    },
    updateMessage(Message) {
      const {IMUI} = this.$refs;
      IMUI.updateMessage(Message)
    }
  },
};
</script>

<style>
.cover {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #ddd;
}

.lemon-editor {
  text-align: left;
  height: 150px;
}

.lemon-editor__inner {
  border-top: solid;
}
</style>